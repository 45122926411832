import React, { useState, useEffect } from 'react';
import { MyButton } from '../../../../../Common/styles';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import NavigationWidget from '../../../NavigationWidget';
import Icons from '../../../../../Common/IconsComponent';
import { useGetEventsMutation } from '../../../../../../services/phpService';
import { useNavigate } from 'react-router-dom';
import Count from './components/Count';
import moment from 'moment-timezone';
import { usePopup } from '../../../../../Popups/PopupContext.js';
import Preloader from '../../../Preloader/Preloader';
import { getTelegramShareLink } from '../../../../../../helpers/referralLinkHelper';

const EventScreen = () => {
	const [getEvent] = useGetEventsMutation();
	const [eventsData, setEventsData] = useState([]);
	const navigate = useNavigate();
	const imageBaseUrl = process.env.REACT_APP_SECRET_IMG;
	const { user } = usePopup();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchEvents = async () => {
			try {
				const res = await getEvent().unwrap();
				setEventsData(res);
			} catch (error) {
				console.error('Ошибка при получении активности:', error);
			}
		};

		fetchEvents();
	}, [getEvent]);

	const hasJoinedEvent = (gamingIds) => {
		if (!user || !user.events_gaming) {
			return false;
		}
		return user.events_gaming.some((event) => gamingIds.includes(event.event_gaming_id));
	};

	const navBack = () => {
		navigate(`/activity`, { replace: true });
	};

	const startEvent = (gamingId, payValueShit, payValueStars, eventId, eventStatus) => {
		const eventData = {
			gamingId,
			payValueShit,
			payValueStars,
			eventId,
			eventStatus,
		};
		localStorage.setItem('eventData', JSON.stringify(eventData));
		navigate(`/startEvent`, {
			state: eventData,
		});
	};

	const continueEvent = () => {
		navigate(`/events/1/game`);
	};

	useEffect(() => {
		const timer = setTimeout(() => {
			setLoading(false);
		}, 750);

		return () => clearTimeout(timer);
	}, []);

	const formatNumber = (num) => {
		if (num >= 1000000) {
			return (Math.round((num / 1000000) * 10) / 10) % 1 === 0
				? `${Math.floor(num / 1000000)}M`
				: `${(num / 1000000).toFixed(1)}M`;
		} else if (num >= 1000) {
			return (Math.round((num / 1000) * 10) / 10) % 1 === 0
				? `${Math.floor(num / 1000)}k`
				: `${(num / 1000).toFixed(1)}k`;
		} else {
			return num.toString();
		}
	};

	return (
		<>
			{loading ? (
				<Preloader />
			) : (
				<div className='projects'>
					<div className='projects__body'>
						<div className='projects__slider'>
							<Swiper
								modules={[Navigation]}
								spaceBetween={50}
								slidesPerView={1}
								loop={true}
							>
								{eventsData.map((event) => {
									const blueVotes = event.gaming[1].total_votes;
									const redVotes = event.gaming[0].total_votes;

									const totalVotes = blueVotes + redVotes;

									const bluePercentage =
										totalVotes === 0 ? 50 : (blueVotes / totalVotes) * 100;
									const redPercentage =
										totalVotes === 0 ? 50 : (redVotes / totalVotes) * 100;

									const containerStyle = {
										width: '100%',
										height: '12px',
										borderRadius: '18px',
										overflow: 'hidden',
									};

									const progressStyle = {
										width: '100%',
										height: '100%',
										background: `linear-gradient(to right, #007AFF ${bluePercentage}%, #FF0000 ${bluePercentage}%)`,
									};

									const currentTime = moment().tz('UTC').unix();
									const isEventStarted = currentTime >= event.start_time;

									const joinedEvent = hasJoinedEvent([
										event.gaming[0].id,
										event.gaming[1].id,
									]);

									return (
										<SwiperSlide key={event.id}>
											<div className='projects__slide'>
												<div className='projects__card' style={{ padding: '18px' }}>
													<MyButton
														onClick={navBack}
														variant='smallIcon'
														style={{ top: '20px', left: '20px' }}
													>
														<Icons.Larrow />
													</MyButton>
													<MyButton
														variant='smallIcon'
														style={{ top: '20px', right: '20px' }}
														onClick={() => {
															const telegramId = user.id_telegram;
															const eventId = event.id;
															const shareLink = getTelegramShareLink(
																telegramId,
																null,
																eventId
															); // генерируем ссылку для события
															window.open(shareLink, '_blank'); // открываем ссылку в новом окне
														}}
													>
														<Icons.Invite />
													</MyButton>
													<div style={{ width: '100%' }}>
														<div className='projects__time'>
															<span>
																<Icons.Clock />{' '}
																{isEventStarted ? 'Ends in:' : 'Starts in:'}
															</span>
															<span style={{ marginTop: '10px' }}>
																<Count
																	time={
																		isEventStarted ? event.end_time : event.start_time
																	}
																/>
															</span>
														</div>
														<div className='projects__progress'>
															Votes
															<div style={containerStyle}>
																<div style={progressStyle} />
															</div>
															<div className='projects__progress-wrap'>
																<span>{blueVotes}</span>
																<span>{redVotes}</span>
															</div>
														</div>
													</div>
													<div className='projects__sides'>
														<div
															className='projects__sides-item'
															style={{
																opacity: joinedEvent
																	? hasJoinedEvent([event.gaming[1].id])
																		? '1'
																		: '0.5'
																	: '1',
															}}
														>
															<div className='projects__sides-img'>
																<img
																	style={{
																		border: hasJoinedEvent([event.gaming[1].id])
																			? '2px solid #000'
																			: 'none',
																	}}
																	src={`${imageBaseUrl}${event.gaming[1].image}`}
																	alt={event.gaming[1].name}
																/>
															</div>
															<div className='projects__sides-name'>
																{hasJoinedEvent([event.gaming[1].id]) && (
																	<Icons.CheckBlack />
																)}
																<span>{event.gaming[1].name}</span>
															</div>
															<div className='projects__sides-amount'>
																<Icons.User />
																<span>{formatNumber(event.gaming[1].total_joined)}</span>
															</div>
														</div>

														<div
															className='projects__sides-item'
															style={{
																fontFamily: 'Kumbh',
																marginTop: '55px',
																fontWeight: '500',
																fontSize: '22px',
															}}
														>
															vs
														</div>

														<div
															className='projects__sides-item'
															style={{
																opacity: joinedEvent
																	? hasJoinedEvent([event.gaming[0].id])
																		? '1'
																		: '0.5'
																	: '1',
															}}
														>
															<div className='projects__sides-img'>
																<img
																	style={{
																		border: hasJoinedEvent([event.gaming[0].id])
																			? '2px solid #000'
																			: 'none',
																	}}
																	src={`${imageBaseUrl}${event.gaming[0].image}`}
																	alt={event.gaming[0].name}
																/>
															</div>
															<div className='projects__sides-name'>
																{hasJoinedEvent([event.gaming[0].id]) && (
																	<Icons.CheckBlack />
																)}
																<span>{event.gaming[0].name}</span>
															</div>
															<div className='projects__sides-amount'>
																<Icons.User />
																<span>{formatNumber(event.gaming[0].total_joined)}</span>
															</div>
														</div>
													</div>
													{joinedEvent && eventsData[0].status === 0 && (
														<div
															className='projects__continue'
															style={{ width: '300px' }}
														>
															<MyButton disabled variant='inactive'>
																Wait for start
															</MyButton>
														</div>
													)}

													{joinedEvent && eventsData[0].status === 1 && (
														<div
															className='projects__continue'
															style={{ width: '300px' }}
														>
															<MyButton
																variant='black'
																style={{ width: '100%' }}
																onClick={continueEvent}
															>
																Play and win
															</MyButton>
														</div>
													)}

													{!joinedEvent && (event.status === 0 || event.status === 1) && (
														<>
															<div
																style={{
																	display: 'flex',
																	justifyContent: 'space-around',
																	width: '100%',
																}}
															>
																<div className='projects__sides-btn'>
																	<MyButton
																		onClick={() =>
																			startEvent(
																				event.gaming[1].id,
																				event.pay_value_shit,
																				event.pay_value_stars,
																				event.id,
																				event.status
																			)
																		}
																		variant='black'
																	>
																		join team
																	</MyButton>
																</div>
																<span style={{ opacity: '0' }}>vs</span>
																<div className='projects__sides-btn'>
																	<MyButton
																		onClick={() =>
																			startEvent(
																				event.gaming[0].id,
																				event.pay_value_shit,
																				event.pay_value_stars,
																				event.id,
																				event.status
																			)
																		}
																		variant='black'
																	>
																		join team
																	</MyButton>
																</div>
															</div>
														</>
													)}
												</div>
											</div>
										</SwiperSlide>
									);
								})}
							</Swiper>
						</div>
					</div>
				</div>
			)}
			<NavigationWidget />
		</>
	);
};

export default EventScreen;
